/**
 * Function to return the URL for summit/series.
 * @returns URL
 */
const gridLink = ( wpLink: string, id: number, type: string, utmParams: string, gridItemName: string, index: number, siteLink: string ) => {
  let formattedLink = '', finalUTMParams='';

  if(gridItemName !== 'search-featured-section'){
    finalUTMParams = utmParams.replace('utm_term=search-result-', `utm_term=search-result-${index}`)
  }else{
    finalUTMParams = utmParams
  }

  if(wpLink === undefined || wpLink === null){
    formattedLink = siteLink + id + '/' + finalUTMParams
  } else {
    formattedLink = wpLink + finalUTMParams
  }
  return formattedLink
}

export default gridLink;
