/**
 *
 * Takes in the tracks array and counts each tracks communication to work out the total session.
 * @param {*} tracks : Track array.
 * @returns number
 */

function sessionsCount(tracks) {
  let countCommunications = 0;
  if (tracks){
	tracks.forEach((track) => {
		countCommunications += track['communications']?.length;
	  });
  }
  return countCommunications;
}

export default sessionsCount;
