import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import styles from './index.module.scss';

/**
 * Component to return the no result content to render based on the condition.
 * @returns JSX No result content
 */
const NoResultsFound = ({searchKey}) => {
  return (
    <div className={styles['no-results-section']}>
      <h3 className={styles['no-results-head']}>
        <FontAwesomeIcon icon={faTriangleExclamation} />
        <span className={styles['results-head']}>No results found</span>
        {(searchKey) ?
          <span> for
          <span className={styles['search-string']}> {searchKey}</span></span>
          : ''
        }
        <span>.</span>
      </h3>
      <p className={styles['no-results-sub-head']}>Suggestions:</p>
      <ul>
        <li>Make sure that all words are spelled correctly.</li>
        <li>Try different keywords.</li>
        <li>Try more general keywords.</li>
      </ul>
    </div>
  );
};

export default NoResultsFound;
