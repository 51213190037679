import React, { FC, useState, useEffect } from 'react';
import SectionHeading from '../SectionHeadings/SectionHeadings';
import SummitsGridItems from '../listGridItems/SummitsGridItems/SummitsGridItems';
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonSummitsGridItems from '../listGridItems/SummitsGridItems/Skeleton';
import LoadMore from '../LoadMore/LoadMore';
import getFetch from '../../libs/getFetch';

interface SummitsProps {
  SummitsGrid: SummitsArray[];
  found: number;
  filter?: 'live' | 'upcoming' | 'recorded'; // Setting this as optional since we do not want to pass from search page.
  type: 'summits' | 'series';
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  footLinkTitle?: string;
  gridItemName?: string;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  listStyle?: string;
  summitsFilterParams?: SummitsFilterParamsArray;
  dataBddTitle?: string;
  dataBddDesc?: string;
  summitUrl: string;
  seriesUrl: string;
  baseUrl: string;
  priorityStatus?: boolean;
  sectionHeading: true | false;
  headingBlockName?: string;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  skeletonHeadingDescription?: boolean;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  isFirstBlock?: boolean;
}

export interface SummitsFilterParamsArray {
  size?: number;
  bq?: string;
  rank?: string;
  rankClosest?: string;
}

export interface SummitsArray {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: 'public' | 'private';
  imageUrl: string;
  companyLogo: string;
  primaryColor: [];
  secondaryColor: [];
  tracks: [];
  featured: boolean;
  wordPressLink: string;
}

const SummitsGrid: FC<SummitsProps> = ({
  SummitsGrid,
  found,
  filter,
  type,
  listLayout,
  cardLayout,
  clickableCard,
  footLinkTitle,
  gridItemName,
  pageType,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  utmParams = '',
  listStyle = 'card',
  summitsFilterParams,
  dataBddTitle,
  dataBddDesc,
  summitUrl,
  seriesUrl,
  baseUrl,
  priorityStatus,
  sectionHeading,
  headingBlockName,
  sectionHeadingTitle,
  sectionHeadingDescription,
  skeletonHeadingDescription,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  isFirstBlock,

}) => {
  let defaultSize;

  if (
    summitsFilterParams?.['size'] > 0 ||
    summitsFilterParams?.['size'] !== undefined
  ) {
    defaultSize = Number(summitsFilterParams['size']);
  } else {
    defaultSize = 6;
  }

  const [summitsData, setSummitsData] = useState(SummitsGrid);
  const [summitCount, setSummitCount] = useState<number>(defaultSize);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getSummits = async () => {
      setIsLoading(SummitsGrid && false);
    };
    getSummits();
  });

  const url = '/api/summits';
  const loadMoreBtn = async () => {
    setSummitCount((prevValue) => prevValue + 6);

    setShowLoadMoreBtn(true);

    // Fetch new set of talks. We call latestTalks endpoint via backend to avoid CORS issue
    const resJson = await getFetch(baseUrl + url, {
      start: summitCount,
      size: defaultSize,
      bq: summitsFilterParams['bq'],
      rank: summitsFilterParams['rank'],
      rankClosest: summitsFilterParams['rankClosest'],
    });

    // Push new set of data into existing array. Using JS spread operator.
    setSummitsData((oldTalksData) => [...oldTalksData, ...resJson['summits']]);

    // Turn off loading spinner
    setShowLoadMoreBtn(false);
  };

  // Hide load more if visible is more less or equals to found talks
  let displayLoadMore;

  if (found > summitCount && SummitsGrid?.length > 0) {
    displayLoadMore = true;
  } else {
    displayLoadMore = false;
  }

     return  <div className={'grid-box'} data-bdd="summits-grid">

      {(() => {

      if ( sectionHeading == true ) {

       if ( isLoading ) {

        return (

         <SkeletonSectionHeadings
          skeletonHeadingDescription={skeletonHeadingDescription}
          {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
         />

        );

       } else if ( SummitsGrid?.length > 0 ) {

        return (
          <>

           <SectionHeading
            title={sectionHeadingTitle}
            description={sectionHeadingDescription}
            link={sectionHeadingLink}
            linkTitle={sectionHeadingLinkText}
            headingBlockName={headingBlockName}
            dataBddTitle={sectionHeadingBddTitle}
            dataBddDesc={sectionHeadingBddDesc}
            dataBddLink={sectionHeadingBddLink}
            {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
           />
          </>
          );

        } else {
          <></>
        }
       }

       })()}

      {(() => {

       if ( isLoading ) {

        return (
          <SkeletonSummitsGridItems />
        );

       } else if ( SummitsGrid?.length > 0 ) {

        return (
         <>

          <SummitsGridItems
            gridData={summitsData}
            type={"summits"} // this can be hardcoded as it's a summits only comp
            rowColSm={rowColSm}
            rowColMd={rowColMd}
            rowColLg={rowColLg}
            rowColXl={rowColXl}
            footLinkTitle={footLinkTitle}
            gridItemName={gridItemName}
            listStyle={listStyle}
            listLayout={listLayout}
            cardLayout={cardLayout}
            pageType={pageType}
            utmParams={utmParams}
            clickableCard={clickableCard}
            dataBddTitle={dataBddTitle}
            dataBddDesc={dataBddDesc}
            summitUrl={summitUrl}
            seriesUrl={seriesUrl}
            priorityStatus={priorityStatus}
          />

          <LoadMore
           onClick={loadMoreBtn}
           loader={showLoadMoreBtn}
           showLoadMore={displayLoadMore}
           gridItemName={gridItemName}
          />
         </>
        );

      } else {
        <></>
      }

    })()}

  </div>

};

export default SummitsGrid;
