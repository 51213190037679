import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonSummitsGridItems from '../../components/listGridItems/SummitsGridItems/Skeleton';
import SkeletonSummitsSearchGridItems from '../../components/listGridItems/SummitsGridItems/Skeleton-Search';
import SummitsGrid from '../../components/SummitsGrid/SummitsGrid';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

// Summit API URL.
const summitApiUrl = '/api/summits';

export const FetchSummits = (baseUrl, params, componentInfo, state = true) => {
  const fullUrl = swrUrlParser(summitApiUrl, params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());

  let summitData;

  if (error) {
    summitData = {
      summits: [],
      found: 0,
    };
  }

  if (!data || data['summits']?.length === 0) {
    summitData = {
      summits: [],
      found: 0,
    };
  } else {
    summitData = {
      summits: data['summits'],
      found: data['found'],
    };
  }

  return (
    <>
      {(() => {
        if (!data) {
          if(componentInfo?.pageType === 'search-page'){
            return (
              <>
                <SkeletonSummitsSearchGridItems />
              </>
            );
          } else {
            return (
              <>
                <SkeletonSectionHeadings
                  skeletonHeadingDescription={false}
                  headingBlockName={componentInfo?.headingBlockName}
                />
                <SkeletonSummitsGridItems />
              </>
            );
          }
        } else if (summitData['summits']?.length > 0) {
          return (
            <>
              <SummitsGrid
                SummitsGrid={summitData['summits']}
                found={summitData['found']}
                type={'summits'}
                filter="upcoming"
                utmParams={componentInfo?.summitsUTM}
                rowColSm={componentInfo?.rowColSm}
                rowColMd={componentInfo?.rowColMd}
                rowColXl={componentInfo?.rowColXl}
                summitsFilterParams={params}
                footLinkTitle={componentInfo?.footLinkTitle}
                gridItemName={componentInfo?.gridItemName}
                dataBddTitle={componentInfo?.dataBddTitle}
                dataBddDesc={componentInfo?.dataBddDesc}
                summitUrl={componentInfo?.summitUrl}
                seriesUrl={componentInfo?.seriesUrl}
                baseUrl={baseUrl}
                priorityStatus={componentInfo?.priorityStatus}
                headingBlockName={componentInfo?.headingBlockName}
                sectionHeading={componentInfo?.sectionHeading}
                sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
                sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
                sectionHeadingDescription={componentInfo?.sectionHeadingDescription} // set as empty to disable description
                sectionHeadingLink={componentInfo?.sectionHeadingLink}
                sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
                sectionHeadingBddDesc={componentInfo?.sectionHeadingBddTitle}
                sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
                skeletonHeadingDescription={false}
                isFirstBlock={false}
                listLayout={componentInfo?.listLayout}
                cardLayout={componentInfo?.cardLayout}
                pageType={componentInfo?.pageType}
                clickableCard={componentInfo?.clickableCard}
              />
            </>
          );
        } else if (summitData['summits']?.length < 1) {
          if(componentInfo?.pageType === 'search-page'){
            return (
            <>
              <NoResultsFound searchKey={params?.q} />
            </>
            );
          }else{
            return (<></>);
          }
        }
      })()}
    </>
  );
};

export default FetchSummits;
